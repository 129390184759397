import React from 'react';
import { useEffect, useMemo, createContext } from 'react';
import { Container, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import { ApplicationStatus } from '../../enums/application-status';
import { Loading } from '../atoms/Loading';
import { Path } from '../../constants/path';
import { ApplyHeading } from '../../components/Label';
import { OccupancyLabelBox } from '../../components/OccupancyLabel';
import { mapStateToProps, mapDispatchToProps } from '../../container/confirm';
import { BaseFab } from '../BaseFab';
import { RegisteredInputContainers, pageDisplayText } from '../../config/item-definitions';
import ConfirmationFormContainerHandler from '../confirmation/ConfirmationFormContainerHandler';
import ApplicationStatusHeader from '../confirmation/ApplicationStatusHeader';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 0 16px',
      position: 'relative'
    },
    applyButton: {
      margin: '24px 0 0',
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-key)',
      width: '100%',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)'
    },
    backButtonContainer: {
      margin: '40px 0',
      textAlign: 'center'
    },
    backButton: {
      color: 'var(--color-gray-3)',
      textDecoration: 'none'
    },
    loadingContainer: {
      margin: '120px auto',
      width: 140,
      '@media (min-width:768px)': {
        height: '500px'
      }
    },
    loadingCaption: {
      color: 'var(--color-key)',
      marginTop: 20,
      fontSize: 16,
      textAlign: 'center'
    }
  })
);

export const ConfimationFormStateContext = createContext<{
  inputValues: any;
  applicationParams: any;
}>({
  inputValues: {},
  applicationParams: {}
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ApplyConfirmTemplate: React.FC<Props> = ({
  inputValues,
  applicationParams,

  property,
  use_og_gas,
  status,
  submitParater
}) => {
  const styles = useStyles({});
  const history = useHistory();

  const displayPlanLabel = pageDisplayText.initialTemplateDisplayPlanLabel;
  const displayPlanName = pageDisplayText.initialTemplateDisplayPlanName;

  useEffect(() => {
    if (status === ApplicationStatus.Submit) {
      history.push(Path.application.complete);
    }
  }, [status, history]);

  // 渡すパラメータを予め格納しておく
  const confirmationFormStateContext = useMemo(
    () => ({
      inputValues: inputValues,
      applicationParams: applicationParams
    }),
    [inputValues, applicationParams]
  );

  return (
    <Container>
      {status !== ApplicationStatus.SubmitLoading && (
        <>
          <Container className={styles.container}>
            {/* 申請状況に応じて警告やエラー情報を表示する */}
            <ApplicationStatusHeader status={status as ApplicationStatus} />

            {/* ここは申し込み種別によるもの、一旦汎用化対象外とする */}
            {displayPlanLabel && displayPlanName && (
              <>
                <ApplyHeading text={'電気(スマモル賃貸プラン)加入'} />
                {property.hasElectricContract === false && <OccupancyLabelBox desc={'スマモル賃貸サービスのみ申込む'} />}
                {property.hasElectricContract === true && <OccupancyLabelBox desc={'スマモル賃貸プランに加入する'} />}
              </>
            )}
          </Container>

          <ConfimationFormStateContext.Provider value={confirmationFormStateContext}>
            {RegisteredInputContainers.map(container => (
              <ConfirmationFormContainerHandler container={container} />
            ))}
          </ConfimationFormStateContext.Provider>

          <BaseFab
            className={styles.applyButton}
            variant="extended"
            size="large"
            aria-label="add"
            onClick={() => submitParater()}
          >
            この内容で申し込む
          </BaseFab>

          <Link to={Path.application.url} style={{ textDecoration: 'none' }}>
            <div className={styles.backButtonContainer}>
              <Button className={styles.backButton}>戻る</Button>
            </div>
          </Link>
        </>
      )}
      {status === ApplicationStatus.SubmitLoading && (
        <div className={styles.loadingContainer}>
          <Loading />
          <p className={styles.loadingCaption}>申し込み中です...</p>
        </div>
      )}
    </Container>
  );
};

export default ApplyConfirmTemplate;
